import NextLink from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

import { useApplicationState } from '../../contexts/application-state-context';
import { useTheme } from '../../contexts/theme-context';
import { ShopObjectType } from '../../generated/graphql';
import ShoppingCartIcon from '../../icons/shopping-cart.svg';
import { getContrastColor } from '../../utils/color';

interface Props {
  shop?: ShopObjectType;
}

export const ShopHeader: React.FC<Props> = (props) => {
  let { shop } = props;
  let router = useRouter();
  let { brandColors } = useTheme();
  let { shoppingCart, organisation } = useApplicationState();

  let name = shop ? shop.name : organisation.name;
  let rootLinkHref = !organisation ? '/' : shop ? '/[organisation]/[shopId]' : '/[organisation]';
  let rootLinkAs = !organisation ? '/' : shop ? `/${organisation.pathname}/${shop._id}` : `/${organisation.pathname}`;

  return (
    <div className="h-16">
      <div
        className="w-full fixed z-40 h-16 border-b"
        style={{
          backgroundColor: brandColors[100],
          borderColor: brandColors[400],
          color: getContrastColor(brandColors[100]),
        }}
      >
        <div className="max-w-screen-xl mx-auto py-2 px-8 h-full flex items-center justify-between">
          <NextLink href={rootLinkHref} as={rootLinkAs} className="font-medium text-lg">
            {name}
          </NextLink>

          {shop && (
            <a
              className="bg-white border py-1 pl-4 pr-2 rounded flex items-center"
              href={`/${router.locale}/${organisation.pathname}/${shop._id}/order/shopping-cart`}
              style={{
                color: brandColors[600],
                borderColor: brandColors[400],
                backgroundColor: getContrastColor(brandColors[600]),
              }}
            >
              <ShoppingCartIcon className="w-6 h-6" />
              <div className="px-2 text-xl font-medium">{shoppingCart.length.toString(10)}</div>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};
