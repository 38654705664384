import { GraphQLClient } from 'graphql-request';
import * as React from 'react';

import { GRAPHQL_ENDPOINT } from '../constants';
import { ReservationObjectType } from '../generated/graphql';
import { useApplicationState } from './application-state-context';
import createContext from './create-context';

export type ShoppingCart = Array<ReservationObjectType>;

type ProviderValue = {
  client: GraphQLClient;
};

let [useContext, ReactProvider] = createContext<ProviderValue>();

export interface Props {
  children: React.ReactNode;
}

export const GraphQLClientProvider: React.FC<Props> = (props) => {
  let { children } = props;
  let { organisation } = useApplicationState();

  let client = React.useMemo(() => {
    return new GraphQLClient(GRAPHQL_ENDPOINT, {
      headers: {
        organisation: organisation._id,
      },
    });
  }, [organisation._id]);

  return <ReactProvider value={{ client }}>{children}</ReactProvider>;
};

export const useGraphQLClient = useContext;
