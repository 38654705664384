import { useTranslator } from '@translator-app/react';
import React from 'react';

import { Heading } from '../atoms/heading';
import { Link } from '../atoms/link';
import { PageFooter } from './page-footer';

export interface NotFoundProps {}

export function NotFound() {
  let { translate } = useTranslator();

  return (
    <div>
      <div className="max-w-screen-xl p-8 mx-auto">
        <div className="min-h-screen">
          <Heading>{translate('errorScreen.notFoundTitle')}</Heading>
          <div>{translate('errorScreen.notFoundDescription')}</div>
          <div className="my-4">
            <Link href="/">{translate('errorScreen.seeAllShops')}</Link>
          </div>
        </div>

        <div className="my-4">
          <PageFooter />
        </div>
      </div>
    </div>
  );
}
