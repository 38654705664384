import { useTranslator } from '@translator-app/react';
import React from 'react';

import { useTheme } from '../../contexts/theme-context';
import { getContrastColor } from '../../utils/color';
import { createSolidDropShadow } from '../../utils/drop-shadow';

export interface IStepProps {
  stepText: string;
  description: string;
  imageUrl: string;
  backgroundColor: string;
}

const Step: React.FC<IStepProps> = (props) => {
  let { stepText, description, imageUrl, backgroundColor } = props;
  let { brandColors } = useTheme();

  const textColor = getContrastColor(backgroundColor);
  return (
    <div className="w-full h-56 relative" style={{ backgroundColor, color: textColor }}>
      <div className="flex flex-col justify-between h-full">
        <div className="pl-8 pr-32 py-4">
          <div
            className="text-sm mb-2 inline-block px-3 py-1 font-bold rounded"
            style={{
              color: brandColors[400],
              backgroundColor: getContrastColor(brandColors[400]),
              boxShadow: createSolidDropShadow(brandColors[400], 4),
            }}
          >
            {stepText}
          </div>
          <div className="text-lg">{description}</div>
        </div>
      </div>
      <div className="absolute z-10 right-5 bottom-1">
        <img src={imageUrl} alt={description} title={description} className="h-48" style={{ maxWidth: 100 }} />
      </div>
    </div>
  );
};

export const Tutorial: React.FC = () => {
  let { translate } = useTranslator();
  let { brandColors } = useTheme();
  let [showTutorial, setShowTutorial] = React.useState(false);

  let lightBackgroundColor = brandColors[100];
  let darkBackgroundColor = brandColors[400];
  let borderColor = brandColors[600];

  return (
    <div
      className="w-full relative"
      style={{ backgroundColor: darkBackgroundColor, color: getContrastColor(darkBackgroundColor) }}
    >
      <div
        className="flex justify-center items-center py-2 font-medium border-b cursor-pointer"
        style={{ borderColor }}
        onClick={() => {
          setShowTutorial(!showTutorial);
        }}
      >
        {translate('tutorial.pressToShow')}
      </div>

      {showTutorial && (
        <div className="border-b relative" style={{ borderColor }}>
          <div className="max-w-screen-xl mx-auto">
            <div className="w-full lg:flex">
              <Step
                stepText="Stap 1"
                imageUrl="/assets/illustrations/phone-girl-dark.svg"
                description={translate('header.steps.viewProducts')}
                backgroundColor={darkBackgroundColor}
              />
              <Step
                stepText="Stap 2"
                imageUrl="/assets/illustrations/phone-boy-dark.svg"
                description={translate('header.steps.receiveNotification')}
                backgroundColor={lightBackgroundColor}
              />
              <Step
                stepText="Stap 3"
                imageUrl="/assets/illustrations/fridge.svg"
                description={translate('header.steps.pickupOrder')}
                backgroundColor={darkBackgroundColor}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
