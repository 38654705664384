import jsCookie from 'js-cookie';
import * as React from 'react';

import { APPLICATION_VERSION, IS_PRODUCTION, SHOPPING_CART_COOKIE_NAME } from '../constants';
import { OrderObjectType, OrganisationObjectType, ReservationObjectType, ShopObjectType } from '../generated/graphql';
import createContext from './create-context';

export type ShoppingCart = Array<ReservationObjectType>;

type ProviderProps = {
  children: React.ReactNode;
  organisation: OrganisationObjectType;
  shop?: ShopObjectType;
  order?: OrderObjectType;
  initialShoppingCart: ShoppingCart;
  reservations: Array<ReservationObjectType>;
};

interface ShoppingCartAction {
  type: 'add' | 'remove' | 'set';
  items: ShoppingCart;
}

type ProviderValue = {
  organisation: OrganisationObjectType;
  shop?: ShopObjectType;
  shopId: string;
  order?: OrderObjectType;
  reservations: Array<ReservationObjectType>;
  shoppingCart: ShoppingCart;
  addToCart: (items: ShoppingCart) => void;
  removeFromCart: (items: ShoppingCart) => void;
  setCart: (items: ShoppingCart) => void;
};

let [useContext, ReactProvider, ReactConsumer] = createContext<ProviderValue>();

function shoppingCartReducer(state: ShoppingCart = [], action: ShoppingCartAction): ShoppingCart {
  let stateClone = [...state];

  if (action.type === 'set') {
    return action.items;
  } else {
    for (let reservation of action.items) {
      let foundIndex = stateClone.findIndex((item) => item._id === reservation._id);
      if (foundIndex > -1 && (action.type === 'add' || action.type === 'remove')) {
        stateClone.splice(foundIndex, 1);
      }

      if (action.type === 'add') {
        stateClone.push(reservation);
      }
    }
  }

  return stateClone;
}

export function ApplicationStateProvider(props: ProviderProps) {
  let { children, organisation, shop, reservations, order, initialShoppingCart } = props;
  let [shoppingCart, dispatchShoppingCartAction]: [
    ShoppingCart,
    (action: ShoppingCartAction) => void
    // @ts-ignore
  ] = React.useReducer(shoppingCartReducer, initialShoppingCart);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      try {
        jsCookie.set(
          SHOPPING_CART_COOKIE_NAME,
          {
            version: APPLICATION_VERSION,
            items: shoppingCart.map((i) => i._id),
          },
          {
            // Expire in 2 days
            expires: 2,
            secure: IS_PRODUCTION,
            sameSite: IS_PRODUCTION ? 'None' : undefined,
          }
        );
      } catch (e) {
        // do nothing really, the user will just get a new cart...
      }
    }
  }, [shoppingCart]);

  reservations = reservations.filter((r) => !!r.vendingProducts?.length);

  return (
    <ReactProvider
      value={{
        organisation,
        shopId: shop ? shop._id : '',
        shop,
        reservations,
        shoppingCart,
        order,
        addToCart: (items) => {
          dispatchShoppingCartAction({
            type: 'add',
            items,
          });
        },
        removeFromCart: (items) => {
          dispatchShoppingCartAction({
            type: 'remove',
            items,
          });
        },
        setCart: (items) => {
          dispatchShoppingCartAction({
            type: 'set',
            items,
          });
        },
      }}
    >
      {children}
    </ReactProvider>
  );
}

export const useApplicationState = useContext;
export const ApplicationStateConsumer = ReactConsumer;
