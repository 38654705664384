import { useTranslator } from '@translator-app/react';
import React from 'react';

import { ApplicationStateProvider } from '../../contexts/application-state-context';
import { GraphQLClientProvider } from '../../contexts/graphql-client';
import {
  OrderObjectType,
  OrganisationObjectType,
  ReservationObjectType,
  ShopObjectType,
} from '../../generated/graphql';
import { ErrorBoundary } from '../organisms/error-boundary';
import { PageFooter } from '../organisms/page-footer';
import { ShopHeader } from '../organisms/shop-header';
import { Tutorial } from '../organisms/tutorial';
import { PageTemplate } from './page-template';

export interface PageTemplateProps {
  children: React.ReactNode | React.ReactNodeArray;
  organisation: OrganisationObjectType;
  shop?: ShopObjectType;
  shoppingCart: Array<ReservationObjectType>;
  reservations: Array<ReservationObjectType>;
  order?: OrderObjectType;
  titleAddition?: string;
  description?: string;
  imageUrl?: string;
}

const PageImpl: React.FC<PageTemplateProps> = (props) => {
  let { children, shop, organisation } = props;

  return (
    <>
      <ShopHeader shop={shop} />

      <Tutorial />

      <div className="bg-gray-100">{children}</div>

      <PageFooter organisation={organisation} shop={shop} />
    </>
  );
};

export const ShopPageTemplate: React.FC<PageTemplateProps> = (props) => {
  let { children, organisation, shop, shoppingCart, order, reservations, description, titleAddition, imageUrl } = props;
  let { translate } = useTranslator();

  const brandColor = organisation?.brandColor;
  const shopName = shop?.name || organisation?.name || 'Woyti';
  const defaultDescription = translate('seo.shopDescription', { shopName });
  const title = [shopName];
  if (titleAddition) {
    title.push(titleAddition);
  }

  return (
    <PageTemplate
      brandColor={brandColor}
      description={description || defaultDescription}
      title={title.join('|')}
      imageUrl={imageUrl}
    >
      <ApplicationStateProvider
        organisation={organisation}
        shop={shop}
        initialShoppingCart={shoppingCart}
        order={order}
        reservations={reservations}
      >
        <GraphQLClientProvider>
          <ErrorBoundary>
            <PageImpl {...props}>{children}</PageImpl>
          </ErrorBoundary>
        </GraphQLClientProvider>
      </ApplicationStateProvider>
    </PageTemplate>
  );
};
